import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import React, { useState } from "react";
import { Button, H4, Small } from "@upsolve/ui";
import trackComponentAction from "../analytics/trackComponentAction";
import { MyUpsolveLink } from "../Links/MyUpsolveLink";
import upsolveStats from "../../../content/onBuild/upsolveStats.json";
import UpsolveLogo from "../../../static/images/upsolve-logo.svg";
import getFilteredReviewsData from "../reviews/getFilteredReviewsData";
import ReviewTile from "../reviews/ReviewTile";
import { $TSFixMe } from "@upsolve/shared/dist/types";
import { CTATarget, getCTARenderedCopy, getCTATrackingTarget, getCTAURL } from "./getCTAData";
import { DebtAdvisorModal } from "../DebtAdvisor/DebtAdvisorModal";
import { createPortal } from "react-dom";
// TODO: update this with dynamic CTAs
const trackingProps = {
  componentName: "CTAUpsolveReviews",
  componentVersion: "1",
};
type CTAUpsolveReviewsProps = {
  trackingPageContext: $TSFixMe;
  dataTestId: string;
  ctaTarget: CTATarget;
};
/**
 * CTAUpsolveReviews
 * - v0: Initial implementation w/ 3 recent reviews
 *
 * @component
 * @version 1
 */
const CTAUpsolveReviews = (props: CTAUpsolveReviewsProps) => {
  // TODO we may need to refactor how callbacks work, it's getting overcomplicated to have separate modals in every CTA compoenent
  const [isDebtTriageShowing, setIsDebtTriageShowing] = useState(false);
  const { pictures } = useStaticQuery(graphql`
    query CTAUpsolveReviewsPictures {
      pictures: allContentfulReview(
        filter: { image: { id: { ne: null } } }
        sort: { fields: [date], order: ASC }
        limit: 10
      ) {
        nodes {
          image {
            altText
            image {
              fluid(maxWidth: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const onClickReviewCTAButton = () => {
    trackComponentAction({
      ...trackingProps,
      actionId: `CTAUpsolveReviews.${getCTATrackingTarget(props.ctaTarget)}`,
      actionMethod: "click",
      pageContext: props.trackingPageContext,
    });

    const targetUrl = getCTAURL(props.ctaTarget);

    if (props.ctaTarget === CTATarget.DEBT_TRIAGE) {
      // Open modal
      setIsDebtTriageShowing(true);
    } else if (targetUrl) {
      // Navigate to href
      window.location.href = targetUrl;
    }
  };

  const { reviews } = getFilteredReviewsData(pictures.nodes);
  return (
    <CTAUpsolveReviewsWrapper dataTestId={props.dataTestId}>
      <StyledUpsolveReviewsContainer>
        <MyUpsolveLink
          className="upsolve-reviews-header"
          href="/reviews/"
          onClick={() =>
            trackComponentAction({
              ...trackingProps,
              actionId: "CTAUpsolveReviews.headerDescription.reviewsNavigation",
              actionMethod: "click",
              pageContext: props.trackingPageContext,
            })
          }
        >
          <div className="group__description">
            <H4 color="brand">Upsolve Member Experiences</H4>
            <Small>{Number(Math.max(upsolveStats.active.total || 0, 600)).toLocaleString()}+ Members Online</Small>
          </div>
          <div className="group__picture">
            <UpsolveLogo />
          </div>
        </MyUpsolveLink>
        <div className="upsolve-reviews">
          {reviews
            .slice(0, 3) // show 3 most recent reviews for now
            .map((review, reviewIndex) => (
              <ReviewTile key={reviewIndex} review={review} showReviewFully />
            ))}
        </div>
        <div className="cta-upsolve-reviews-wrapper__link">
          <Button size="md" width="100%" onClick={onClickReviewCTAButton}>
            {getCTARenderedCopy(props.ctaTarget).buttonText}
          </Button>
        </div>
      </StyledUpsolveReviewsContainer>
      {isDebtTriageShowing &&
        createPortal(
          <DebtAdvisorModal
            trackingPageContext={props.trackingPageContext}
            onHide={() => setIsDebtTriageShowing(false)}
          />,
          document.body
        )}
    </CTAUpsolveReviewsWrapper>
  );
};

const StyledUpsolveReviewsContainer = styled.div`
  background: transparent;
  padding: 0;

  .cta-upsolve-reviews-wrapper__link {
    margin: 0;
    padding: 1em 2em;
    font-weight: 700;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    a {
      color: #fff;
    }
  }

  .upsolve-reviews-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75em 1em;
    margin: 0;
    background: ${(props) => props.theme.colors.white[900]};
    border-radius: 24px 24px 0 0;
    border: 1px solid ${(props) => props.theme.colors.white[300]};
    @media (max-width: ${(p) => p.theme.breakpoints[500]}) {
      padding: 0.5em 0.5em;
      flex-direction: column-reverse;
    }
  }

  .upsolve-reviews {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0 0.5em;
    background: ${(props) => props.theme.colors.white[700]};
  }

  .group__picture {
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    margin: 20px 0;
    & > div {
      height: 100%;
      width: 100%;
    }
    svg {
      width: 100px;
    }
    img {
      min-width: 150px;
    }
    @media (max-width: ${(p) => p.theme.breakpoints[500]}) {
      margin: 10px 0;
      padding: 0 10px;
    }
  }

  .group__description {
    margin-top: 0.25em;
    margin-left: 0.5em;
    h4 {
      font-weight: 900;
    }
    h4,
    p,
    small {
      margin: 0;
      padding: 0;
      text-align: left;
    }
    small {
      color: ${(props) => props.theme.colors.gray[900]};
      font-weight: 300;
      white-space: nowrap;
    }
  }
`;

const CTAUpsolveReviewsWrapper = styled.div.attrs((props: { dataTestId: string }) => ({
  "data-test-id": props.dataTestId,
}))`
  box-shadow: ${(props) => props.theme.effects.shadow[300]};
  border-radius: 24px;
  overflow: hidden;
  padding: 0;
  & > p {
    padding: 0 1em;
    font-weight: 500;
  }
  @media (max-width: 45em) {
    margin: 0 -1em;
  }
`;

export default CTAUpsolveReviews;
